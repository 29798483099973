<template>
  <div class="homepage__layout-wrap" :class="homepageDynamicClass">
    <main class="homepage-wrap">
      <homepage-top-article
        v-if="isDataValid.TopArticles"
        :article="homepageData.Fm.Articles.TopArticles[0]"
        :last-modified="lastModifiedByRequest.FmArticles"
      />

      <homepage-latest-news
        v-if="isDataValid.LatestNews"
        :articles="homepageData.Fm.Articles.LatestNewsArticles"
        :last-modified="lastModifiedByRequest.FmArticles"
      />

      <homepage-combined
        :data-ref="$options.consts.REFS.HOMEPAGE_COMBINED_SECTION"
        :education-center-articles="homepageData.Fm.Articles.EducationArticles"
        :thought-leadership-articles="
          homepageData.Fm.Articles.ThoughtLeadershipArticles
        "
        :directory-categories="homepageData.FmDir.Categories"
        :directory-companies="homepageData.FmDir.Companies"
        :directory-news="homepageData.FmDir.News"
        :most-viewed-articles="homepageData.Fm.Articles.MostViewedArticles"
        :is-data-valid="isDataValid"
        :last-modified-by-request="lastModifiedByRequest"
      />

      <homepage-videos
        v-if="isDataValid.Videos"
        :data-ref="$options.consts.REFS.HOMEPAGE_VIDEOS_SECTION"
        :videos="homepageData.Fm.Videos"
        :last-modified="lastModifiedByRequest.Videos"
      />

      <homepage-events
        v-if="isDataValid.Events"
        :events="homepageData.Fm.Events"
        :last-modified="lastModifiedByRequest.Events"
      />

      <homepage-forexlive
        :latest-news="homepageData.Fl.Articles.LatestNews"
        :technical-analysis-news="homepageData.Fl.Articles.TechnicalAnalysis"
        :is-data-valid="isDataValid"
        :last-modified-by-request="lastModifiedByRequest"
      />

      <a-visibility show :on="[$breakpoint.mobile]">
        <a-interstitial-banner
          :banner-settings="BANNER_SETTINGS.HOMEPAGE.INTERSTITIAL"
          class="homepage__interstitial-banner"
        />
      </a-visibility>

      <!--      <homepage-onboarding-flow-->
      <!--        v-if="isOnboardingFlowVisible"-->
      <!--        :is-ebook-loaded="isEbookLoaded"-->
      <!--        :is-ebook-should-be-rendered="true"-->
      <!--      />-->
    </main>
  </div>
</template>

<script>
import mixins from '~/utils/mixins'
import { PAGE_SCHEME_TYPE } from '@/utils/enums/pageSchemes'
import { BANNER_SETTINGS } from 'enums/banners/banner-settings'
import { META_PARAM, REDIRECT_TYPE, REDIRECT_TYPE_CODES } from 'enums/seo'
import { ASSISTANT_BUTTON_ID } from 'shared/AAssistantButton/enums'
// import { ONBOARDING_STORAGE_KEY } from 'enums/onboarding/general'
import { REFS } from 'enums/external-refs'
import { mapGetters } from 'vuex'
import { throwPageNotFoundError } from '@/plugins/error'
import { generateArticlePathBySlugs } from '@/plugins/helper'
import { GOOGLE_OPTIMIZE_SCRIPT } from 'enums/google-optimize'
import { QUERY_PARAM } from 'enums/query-param'

import { hydrateWhenVisible } from '@/utils/helpers/vue-lazy-hydration/LazyHydrate'
const hydrationOptions = { observerOptions: { rootMargin: '100px' } }

function isNonEmptyArrayWithField(value, field) {
  if (!value) return false
  if (!Array.isArray(value)) return false

  return value.length && !!value.some(item => item && item[field])
}

const COMPANY_SLOT_COUNT = 10

/**
 * This function generates array of 10 slots with directory companies.
 * Some slots could be empty and in such a case will be rendered as empty slot
 * @param directoryCompanies
 */
function generateCompanySlots(directoryCompanies) {
  const companySlots = new Array(COMPANY_SLOT_COUNT).fill(null)
  directoryCompanies.forEach((company, index) => {
    const targetIndex = company.Order == null ? index : company.Order
    companySlots[targetIndex] = company
  })
  return companySlots
}

export default {
  name: 'Homepage',
  mixins: [
    mixins.urlFormatters,
    mixins.setPageLoaded,
    mixins.initialScroll,
    mixins.oneTrust
  ],
  components: {
    // HomepageOnboardingFlow: () =>
    //   import('@/components/_homepage/HomepageOnboardingFlow'),
    AInterstitialBanner: hydrateWhenVisible(
      () => import('@/components/_layout/AInterstitialBanner'),
      { ...hydrationOptions, props: ['banner-settings'] }
    ),
    HomepageTopArticle: hydrateWhenVisible(
      () => import('@/components/_homepage/Sections/HomepageTopArticle'),
      { ...hydrationOptions, props: ['article', 'last-modified'] }
    ),
    HomepageLatestNews: hydrateWhenVisible(
      () => import('@/components/_homepage/Sections/HomepageLatestNews'),
      { ...hydrationOptions, props: ['articles', 'last-modified'] }
    ),
    HomepageCombined: hydrateWhenVisible(
      () => import('@/components/_homepage/Sections/HomepageCombined'),
      {
        ...hydrationOptions,
        props: [
          'education-center-articles',
          'thought-leadership-articles',
          'directory-categories',
          'directory-companies',
          'directory-news',
          'most-viewed-articles',
          'is-data-valid',
          'last-modified-by-request'
        ]
      }
    ),
    HomepageVideos: hydrateWhenVisible(
      () => import('@/components/_homepage/Sections/HomepageVideos'),
      { ...hydrationOptions, props: ['videos', 'last-modified'] }
    ),
    HomepageEvents: hydrateWhenVisible(
      () => import('@/components/_homepage/Sections/HomepageEvents'),
      { ...hydrationOptions, props: ['events', 'last-modified'] }
    ),
    HomepageForexlive: hydrateWhenVisible(
      () => import('@/components/_homepage/Sections/HomepageForexlive'),
      {
        ...hydrationOptions,
        props: [
          'latest-news',
          'technical-analysis-news',
          'is-data-valid',
          'last-modified-by-request'
        ]
      }
    )
  },
  head() {
    const {
      Fm: {
        Articles: {
          LatestNewsArticles = [],
          TopArticles = [],
          ThoughtLeadershipArticles = [],
          EducationArticles = [],
          MostViewedArticles = []
        },
        Videos = {},
        Events
      },
      Fl: {
        Articles: { LatestNews: FlLatestNews }
      }
    } = this.homepageData

    /**
     * Temporary hardcode the title due to the bug FMP-17163
     */
    return this.$generateMetaTags({
      titleParam: META_PARAM.HOMEPAGE,
      descriptionParam: META_PARAM.HOMEPAGE,
      schemes: [
        { type: PAGE_SCHEME_TYPE.WEB_SITE },
        { type: PAGE_SCHEME_TYPE.ORGANIZATION },
        ...this.$helper.generateVideoSchemasFromResponse([
          TopArticles[0]?.featuredVideo,
          ...LatestNewsArticles.map(
            category => category.Articles?.[0]?.featuredVideo
          ),
          ...EducationArticles.map(article => article?.featuredVideo),
          ...ThoughtLeadershipArticles.map(article => article?.featuredVideo),
          ...MostViewedArticles.map(article => article?.featuredVideo),
          Events?.VideoSettings?.Video,
          ...Object.values(Videos).map(videoSettings => videoSettings.Video),
          ...(FlLatestNews || []).map(article => article?.FeaturedVideo)
        ])
      ],
      additionalLinks: [
        {
          rel: 'alternate',
          type: 'application/rss+xml',
          title: 'Finance Magnates | Financial and business news',
          href: `${this.$env.DOMAIN_URL}/feed`
        }
      ],
      source: 'seo/homepageSeo',
      script: [GOOGLE_OPTIMIZE_SCRIPT],
      canonicalUrl: this.canonicalUrl
    })
  },
  async asyncData(ctx) {
    const dispatch = ctx.store.dispatch

    const {
      query: { p: legacyArticleId }
    } = ctx.route

    /**
     * Handle shortlinks
     */
    if (legacyArticleId) {
      const payload = { wp_id: legacyArticleId }
      try {
        const { Slug, CategorySlug, SubCategorySlug } = await dispatch(
          'articles/requestMigratedArticleSlugsByShortLink',
          payload
        )

        const articleUrl = generateArticlePathBySlugs({
          articleSlug: Slug,
          categorySlug: CategorySlug,
          subcategorySlug: SubCategorySlug
        })

        return ctx.redirect(
          REDIRECT_TYPE_CODES[REDIRECT_TYPE.MOVED_PERMANENTLY],
          articleUrl
        )
      } catch (err) {
        return throwPageNotFoundError(ctx, 'HomePage with shortlink')
      }
    }

    const promiseSettings = [
      {
        promise: dispatch('articles/requestHomepageFmArticles'),
        required: false,
        errorMessage:
          'Error has occurred while trying to get Homepage - Fm Articles',
        fallbackData: []
      },
      {
        promise: dispatch('articles/requestDirectoryCategories'),
        required: false,
        errorMessage:
          'Error has occurred while trying to get Homepage - Directory Categories',
        fallbackData: []
      },
      {
        promise: dispatch('articles/requestDirectoryCompanies'),
        required: false,
        errorMessage:
          'Error has occurred while trying to get Homepage - Directory Companies',
        fallbackData: []
      },
      {
        promise: dispatch('articles/requestDirectoryNews'),
        required: false,
        errorMessage:
          'Error has occurred while trying to get Homepage - Directory News',
        fallbackData: []
      },
      {
        promise: dispatch('articles/requestHomepageVideos'),
        required: false,
        errorMessage:
          'Error has occurred while trying to get Homepage - Videos section',
        fallbackData: []
      },
      {
        promise: dispatch('articles/requestHomepageEvents'),
        required: false,
        errorMessage:
          'Error has occurred while trying to get Homepage - Events section',
        fallbackData: {}
      },
      {
        promise: dispatch('articles/requestHomepageFlMarketNews'),
        required: false,
        errorMessage:
          'Error has occurred while trying to get Homepage - FL Latest News',
        fallbackData: []
      },
      {
        promise: dispatch('articles/requestHomepageFlTechnicalAnalysisNews'),
        required: false,
        errorMessage:
          'Error has occurred while trying to get Homepage - FL Technical Analysis News',
        fallbackData: []
      },
      /**
       * We should request form settings here to avoid DOM mismatch when the
       * Newsletter form is rendered on the Homepage.
       */
      {
        promise: dispatch('leads/requestFormsSettings'),
        required: true,
        errorMessage: 'Error has occurred while trying to get form settings',
        fallbackData: {}
      }
    ]
    try {
      const promiseAllResult = await ctx.app.$helper.promiseAllWithFallback(
        promiseSettings,
        ctx
      )

      const [
        {
          data: {
            TopArticles,
            LatestNewsArticles,
            EducationArticles,
            MostViewedArticles,
            ThoughtLeadershipArticles,
            lastModified: fmArticlesLastModified
          }
        },
        {
          data: {
            Categories: DirectoryCategories,
            lastModified: directoryCategoriesLastModified
          }
        },
        {
          data: {
            Companies: DirectoryCompanies,
            lastModified: directoryCompaniesLastModified
          }
        },
        {
          data: { News: DirectoryNews, lastModified: directoryNewsLastModified }
        },
        {
          data: { Videos, lastModified: videosLastModified }
        },
        {
          data: { Events, lastModified: eventsLastModified }
        },
        {
          data: {
            Articles: ForexliveLatestNews,
            lastModified: forexliveLatestNewsLastModified
          }
        },
        {
          data: {
            Articles: ForexliveTechnicalAnalysis,
            lastModified: forexliveTechnicalAnalysisLastModified
          }
        }
      ] = promiseAllResult

      return {
        homepageData: {
          Fm: {
            Articles: {
              TopArticles,
              LatestNewsArticles,
              EducationArticles,
              ThoughtLeadershipArticles,
              MostViewedArticles
            },
            Videos,
            Events
          },
          FmDir: {
            Categories: DirectoryCategories,
            Companies: generateCompanySlots(DirectoryCompanies),
            News: DirectoryNews
          },
          Fl: {
            Articles: {
              LatestNews: ForexliveLatestNews,
              TechnicalAnalysis: ForexliveTechnicalAnalysis
            }
          }
        },
        lastModifiedByRequest: {
          FmArticles: fmArticlesLastModified,
          DirectoryCompanies: directoryCompaniesLastModified,
          DirectoryCategories: directoryCategoriesLastModified,
          DirectoryNews: directoryNewsLastModified,
          Videos: videosLastModified,
          Events: eventsLastModified,
          ForexliveLatestNews: forexliveLatestNewsLastModified,
          ForexliveTechnicalAnalysis: forexliveTechnicalAnalysisLastModified
        }
      }
    } catch (err) {
      ctx.app.$errorHandler(err, ctx, { showErrorPage: true })
    }
  },
  consts: {
    ASSISTANT_BUTTON_ID,
    REFS
  },
  data() {
    return {
      canonicalUrl: this.$env.DOMAIN_URL,
      BANNER_SETTINGS,
      homepageData: {
        Fm: {
          Articles: {
            TopArticles: [],
            LatestNewsArticles: [],
            EducationArticles: [],
            ThoughtLeadershipArticles: [],
            MostViewedArticles: []
          },
          Videos: [],
          Events: {}
        },
        FmDir: {
          Categories: [],
          Companies: [],
          News: []
        },
        Fl: {
          Articles: {
            LatestNews: [],
            TechnicalAnalysis: []
          }
        }
      },
      lastModifiedByRequest: {
        FmArticles: null,
        DirectoryCompanies: null,
        DirectoryCategories: null,
        DirectoryNews: null,
        Videos: null,
        Events: null,
        ForexliveLatestNews: null,
        ForexliveTechnicalAnalysis: null
      }
    }
  },
  computed: {
    ...mapGetters({
      isPreviewMode: 'isPreviewMode'
    }),
    homepageDynamicClass() {
      const {
        [QUERY_PARAM.HIGHLIGHT_CUSTOM_FONT]: highlight
      } = this.$route.query

      return {
        'highlight-custom-fonts': !!highlight
      }
    },
    // isOnboardingFlowVisible() {
    //   return (
    //     process.client &&
    //     !this.isPreviewMode &&
    //     !localStorage.getItem(ONBOARDING_STORAGE_KEY.HOMEPAGE)
    //   )
    // },
    isDataValid() {
      return {
        TopArticles: this.isTopArticlesDataValid,
        LatestNews: this.isLatestNewsDataValid,
        EducationArticles: this.isEducationArticlesDataValid,
        ThoughtLeadershipArticles: this.isThoughtLeadershipArticlesDataValid,
        MostViewedArticles: this.isMostViewedArticlesDataValid,
        DirectoryCompanies: this.isDirectoryCompaniesDataValid,
        DirectoryCategories: this.isDirectoryCategoriesDataValid,
        DirectoryNews: this.isDirectoryNewsDataValid,
        Videos: this.isVideosDataValid,
        Events: this.isEventDataValid,
        ForexliveLatestNews: this.isForexliveLatestNewsDataValid,
        ForexliveTechnicalAnalysisNews: this
          .isForexliveTechnicalAnalysisNewsDataValid
      }
    },
    isTopArticlesDataValid() {
      try {
        const { TopArticles } = this.homepageData.Fm.Articles
        return isNonEmptyArrayWithField(TopArticles, 'slug')
      } catch (err) {
        this.$errorHandler(err, this)
        return false
      }
    },
    isLatestNewsDataValid() {
      try {
        const { LatestNewsArticles } = this.homepageData.Fm.Articles
        return (
          LatestNewsArticles.length &&
          LatestNewsArticles.every(category =>
            isNonEmptyArrayWithField(category.Articles, 'slug')
          )
        )
      } catch (err) {
        this.$errorHandler(err, this)
        return false
      }
    },
    isEducationArticlesDataValid() {
      try {
        const { EducationArticles } = this.homepageData.Fm.Articles
        return isNonEmptyArrayWithField(EducationArticles, 'slug')
      } catch (err) {
        this.$errorHandler(err, this)
        return false
      }
    },
    isThoughtLeadershipArticlesDataValid() {
      try {
        const { ThoughtLeadershipArticles } = this.homepageData.Fm.Articles
        return isNonEmptyArrayWithField(ThoughtLeadershipArticles, 'slug')
      } catch (err) {
        this.$errorHandler(err, this)
        return false
      }
    },
    isMostViewedArticlesDataValid() {
      try {
        const { MostViewedArticles } = this.homepageData.Fm.Articles
        return isNonEmptyArrayWithField(MostViewedArticles, 'slug')
      } catch (err) {
        this.$errorHandler(err, this)
        return false
      }
    },
    isDirectoryCompaniesDataValid() {
      try {
        const { Companies } = this.homepageData.FmDir
        return isNonEmptyArrayWithField(Companies, 'Slug')
      } catch (err) {
        this.$errorHandler(err, this)
        return false
      }
    },
    isDirectoryCategoriesDataValid() {
      try {
        const { Categories } = this.homepageData.FmDir
        return isNonEmptyArrayWithField(Categories, 'Slug')
      } catch (err) {
        this.$errorHandler(err, this)
        return false
      }
    },
    isDirectoryNewsDataValid() {
      try {
        const { News } = this.homepageData.FmDir
        return isNonEmptyArrayWithField(News, 'Slug')
      } catch (err) {
        this.$errorHandler(err, this)
        return false
      }
    },
    isVideosDataValid() {
      try {
        const { Videos } = this.homepageData.Fm
        return isNonEmptyArrayWithField(Videos, 'Video')
      } catch (err) {
        this.$errorHandler(err, this)
        return false
      }
    },
    isEventDataValid() {
      try {
        const eventData = this.homepageData.Fm.Events
        const keys = [
          'Events',
          'MainEvent',
          'Newsletter',
          'Testimonials',
          'VideoSettings'
        ]

        if (!keys.every(key => eventData[key])) return false
        if (
          !eventData.Events.EventsList ||
          !isNonEmptyArrayWithField(eventData.Events.EventsList, 'Title')
        ) {
          return false
        }

        return isNonEmptyArrayWithField(eventData.Testimonials.Items, 'Title')
      } catch (err) {
        this.$errorHandler(err, this)
        return false
      }
    },
    isForexliveLatestNewsDataValid() {
      try {
        const { LatestNews } = this.homepageData.Fl.Articles
        return isNonEmptyArrayWithField(LatestNews, 'Slug')
      } catch (err) {
        this.$errorHandler(err, this)
        return false
      }
    },
    isForexliveTechnicalAnalysisNewsDataValid() {
      try {
        const { TechnicalAnalysis } = this.homepageData.Fl.Articles
        return isNonEmptyArrayWithField(TechnicalAnalysis, 'Slug')
      } catch (err) {
        this.$errorHandler(err, this)
        return false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$latest-section-width: 710px;
$content-width-desktop-lg: 990px;

.homepage-wrap {
  .homepage__interstitial-banner {
    position: fixed;
  }

  & > * {
    position: relative;
  }

  /deep/ & > section {
    margin-top: 40px;

    @include mobile {
      margin-top: 20px;
    }

    &:first-child {
      margin-top: 0;
    }
  }

  & > *:last-child {
    &::after {
      display: none;
    }
  }

  /deep/ .input__wrapper,
  /deep/ .a-button__general-style {
    border-radius: 5px;
  }
}
</style>
